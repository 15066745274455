<template>
  <v-container
    id="group-contacts"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-dialog
      v-model="isSurveyListDialogVisible"
      persistent
      max-width="490"
    >
      <v-card>
        <v-card-title class="headline">
          Select survey to send
        </v-card-title>
        <div id="surveySelector">
          <v-select
            v-model="selectedSurvey"
            :items="surveys"
            outlined
            item-text="label"
            item-value="fid"
            label="Select a survey"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!selectedSurvey"
            color="green"
            dark
            class="mb-2 mr-2"
            @click="sendSurveyLinks"
          >
            Send Survey Link
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="hideSurveyDialog"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-v-component
      :heading="
        currentGroup && currentGroup.name ? currentGroup.name : 'Loading group'
      "
      sub-heading="List of Group Membership"
    />

    <template>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-select
          v-model="selectedStatus"
          :items="statusFilters"
          label="Filter by Status"
          solo
          class="dropdown"
        />
        <v-text-field
          v-model="searchText"
          v-debounce:300ms="_loadData"
          class="searchBox"
          label="Search"
        />
        <v-spacer />

        <bulk-message-dialog
          :contacts="selected"
          :group="currentGroup"
          :templates="templates"
          :languages-arr="languagesArr"
          @on-send-start="messageSendInitiated"
          @on-success="messageSent"
          @on-error="messageError"
        />
        <v-btn
          v-if="
            !(selected && selected.length) ||
              (selected &&
                selected.length &&
                contacts &&
                contacts.length &&
                selected.length === contacts.length)
          "
          color="primary"
          dark
          class="ml-2"
          @click="composeMassEmail"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-message
          </v-icon>
          <span>
            Compose mass email
          </span>
        </v-btn>

        <group-mass-email-composer
          :is-active="isMassEmailDialogVisible"
          :contacts="selected"
          :group-id="groupId"
          @on-dismiss="dismissMassEmailDialog"
          @on-success="emailSent"
        />

        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.firstName"
                      required
                      label="First Name *"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.lastName"
                      required
                      label="Last Name *"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="editedItem.cellNumber"
                      required
                      label="Cell Number *"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="selectedGroup"
                      :items="groups"
                      label="Group *"
                      item-text="name"
                      item-value="id"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>* indicates required field</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-toolbar
        flat
        color="transparent"
      >
        <div class="tableTools">
          <dial-number-dialog
            v-if="isClickToCallEnabled"
            :contacts="selected"
            :group="currentGroup"
            @on-send-start="callRequestInitiated"
            @on-success="callInitiated"
            @on-error="callError"
          />

          <v-btn
            v-if="isSurveyEnabled"
            :disabled="!selected || !selected.length"
            color="green"
            dark
            class="mb-2 mr-2"
            @click="showSurveyList"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-content-paste
            </v-icon>
            Send Survey Link
          </v-btn>
          <v-btn
            :disabled="!selected || !selected.length"
            color="green"
            dark
            class="mb-2 mr-2"
            @click="downloadCSVData"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-download
            </v-icon>
            CSV
          </v-btn>

          <v-btn
            v-if="isvideoConfEnabled"
            :disabled="!selected || !selected.length"
            color="#00B0FF"
            dark
            class="mb-2 mr-2"
            @click="initiateVideoCall"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-video
            </v-icon>
            &nbsp; &nbsp; Video Conference
          </v-btn>
        </div>
      </v-toolbar>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="contacts"
      item-key="cellNumber"
      show-select
      :options.sync="options"
      sort-by="firstName"
      :server-items-length="maxOptions"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [500, 100, 1000, 1500, 2000],
      }"
      @toggle-select-all="selectAllToggle"
    >
      <template v-slot:item.firstName="{ item }">
        <span
          class="cellLink"
          @click="_showContactProfileDialog(item)"
        >
          {{ item.firstName }}
        </span>
      </template>
      <template v-slot:item.lastName="{ item }">
        <span
          class="cellLink"
          @click="_showContactProfileDialog(item)"
        >
          {{ item.lastName }}
        </span>
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :disabled="item.contactStatus === 'unsubscribed'"
          @input="select($event)"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <router-link :to="{ name: 'Message History', params: { contactId: item.id }}"> -->
        <div class="actionsWrapper">
          <v-icon
            small
            class="ml-4"
            @click="_showContactProfileDialog(item)"
          >
            mdi-account
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
      <template v-slot:item.messaging="{ item }">
        <v-icon
          small
          @click="_showMessagingDialog(item)"
        >
          mdi-message-draw
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="mb-4 mt-4">
          No group contacts to display
        </p>
        <v-btn
          color="primary"
          outlined
          class="mb-4"
          @click="_loadData"
        >
          Refresh
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import { mapState } from 'vuex'
  import Vue from 'vue'
  import vueDebounce from 'vue-debounce'
  import EventEmitter from '../../../utils/event-emitter'

  Vue.use(vueDebounce)

  // Listening to multiple events
  Vue.use(vueDebounce, {
    listenTo: ['input', 'keyup'],
  })

  // Setting a default timer This is set to '300ms' if not specified
  Vue.use(vueDebounce, {
    defaultTime: '700ms',
  })

  export default {
    components: {
      BulkMessageDialog: () => import('../components/BulkMessageDialog'),
      DialNumberDialog: () => import('../components/DialNumberDialog'),
      GroupMassEmailComposer: () =>
        import('../../../features/email-messaging/group-mass-email-composer/group-mass-email-composer.vue'),
    },
    props: {
      groupId: {
        required: true,
        type: String,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      dialog: false,
      isSurveyListDialogVisible: false,
      surveys: [],
      // EventEmitter: EventEmitter,
      templates: [],
      languagesArr: [
        { name: 'English', value: 'en' },
        { name: 'Chinese', value: 'zh' },
        { name: 'Spanish', value: 'es' },
      ],
      selectedSurvey: null,
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Cell Number', value: 'cellNumber' },
        { text: 'Email', value: 'email' },
        { text: 'Subsciption Status', value: 'contactStatus' },
        { text: 'Message Sent', value: 'messageSent', width: '25%' },
        { text: 'Response', value: 'responseRecieved', width: '25%' },
        { text: '', value: 'messaging', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      snackbar: false,
      notification: 'Unknown',
      selectedGroup: 0,
      selected: [],
      searchText: '',
      filteredContacts: [],
      isMassEmailDialogVisible: false,
      currentGroup: {
        name: '',
        id: -1,
      },
      contacts: [],
      editedIndex: -1,
      editedItem: {
        id: -1,
        firstName: '',
        lastName: '',
        cellNumber: '',
        groupId: '',
      },
      authenTokenForVideoCall: '',
      defaultItem: {
        id: -1,
        firstName: '',
        lastName: '',
        cellNumber: '',
        groupId: '',
      },
      statusFilters: [
        {
          text: 'Subscribed',
          value: '2',
        },
        {
          text: 'New',
          value: '1',
        },
        {
          text: 'Unsubscribed',
          value: '3',
        },
        {
          text: 'All',
          value: '0',
        },
      ],
      isOverlayVisible: false,
      groups: [],
      isSurveyEnabled: false,
      isvideoConfEnabled: false,
      isClickToCallEnabled: false,
      loading: false,
      options: {},
      maxOptions: 0,
      selectedStatus: 0,
    }),

    computed: {
      ...mapState(['appAddOns']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Contact' : 'Edit Contact'
      },
    },

    watch: {
      options: {
        handler (props) {
          this._loadData(props)
        },
        deep: true,
      },
      selectedStatus (val) {
        this._loadData()
      },
      dialog (val) {
        val || this.close()
      },
      appAddOns (val) {
        this._setPermissionsFromAddons(val)
      },
    },

    async created () {
      this._setPermissionsFromAddons(this.$store.state.appAddOns)
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      _setPermissionsFromAddons (val) {
        if (val && val.length) {
          const surveyAddOn = val.find(addOn => addOn.AppAddOnID === 3)
          if (surveyAddOn) {
            this.isSurveyEnabled = surveyAddOn.isEnabled
          }
          const videoConf = val.find(addOn => addOn.AppAddOnID === 4)
          if (videoConf) {
            this.isvideoConfEnabled = videoConf.isEnabled
          }
          const callAddOn = val.find(addOn => addOn.AppAddOnID === 5)
          if (callAddOn) {
            this.isClickToCallEnabled = callAddOn.isEnabled
          }
        }
      },
      async _loadData (props = null) {
        console.log(props)
        const searchText = this.searchText.trim().toLowerCase()

        let payload = {}
        if (props && props.page) {
          payload = {
            offset: props.page,
            searchterm: searchText,
            perPage: props.itemsPerPage,
            groupid: this.groupId,
          }
          if (props.sortBy && props.sortBy.length) {
            payload.sortBy = this._formatSortProperty(props.sortBy[0])
            payload.isAsc = props.sortDesc[0]
          }
        } else {
          payload = {
            offset: 1,
            searchterm: searchText,
            perPage: 10,
            groupid: this.groupId,
          }
        }
        payload.filter = this.selectedStatus
        this.loading = true
        try {
          const response = await this._makePaginatedApiCall(payload)
          if (response && response.data && response.data.data) {
            this.contacts = this._formatContacts(response.data.data)
            this.maxOptions = response.data.totalCount
          }
        } catch (error) {
          console.log('error :>> ', error)
        }
        this.loading = false
      },
      /**
       * Add some formatting to the selected sortby option
       * (As per backend requirement)
       */
      _formatSortProperty (sortBy) {
        let formattedSortProperty = sortBy
        switch (sortBy) {
          case 'messageSent': {
            formattedSortProperty = 'datesent'
            break
          }
          case 'responseRecieved': {
            formattedSortProperty = 'responsedate'
            break
          }
          default: {
            formattedSortProperty = sortBy.toLowerCase()
          }
        }
        return formattedSortProperty
      },
      _formatContacts (contacts) {
        return contacts.map(contact => ({
          id: contact.ContactID,
          firstName: contact.FirstName,
          lastName: contact.LastName,
          cellNumber: contact.CellNumber,
          studentId: contact.StudentID,
          contactStatus: contact.ContactStatus,
          groupId: contact.GroupID,
          email: contact.Email && contact.Email.length ? contact.Email : '--',
          messageSent: contact.Message,
          responseRecieved: contact.Response,
        }))
      },
      _showMessagingDialog (contact) {
        EventEmitter.dispatch('show-messaging-popup', contact)
      },

      _showContactProfileDialog (contact) {
        EventEmitter.dispatch('show-contact-profile-popup', contact)
      },

      async _makePaginatedApiCall (payload) {
        return await this.httpConnector.makeRequest(
          'post',
          `${this.apiEndpoint}group/contacts/info?code=${this.apiCode}`,
          payload,
        )
      },
      async initialize () {
        try {
          this.isOverlayVisible = true
          // Combine multiple calls to minimise the network call time
          const apiCalls = await Promise.all([
            this.httpConnector.makeEncryptedRequest(
              'get',
              `${this.apiEndpoint}groups?code=${this.apiCode}`,
            ),
            this.httpConnector.makeEncryptedRequest(
              'get',
              `${this.apiEndpoint}template/list?code=${this.apiCode}`,
            ),
          ])
          const groupResponse = apiCalls[0]
          this.groups = []
          groupResponse.forEach(group => {
            this.groups.push({
              ...group,
              id: group.groupID,
              name: group.name,
              signiture: group.signiture,
            })
          })
          this.currentGroup = this.groups.find(
            group => group.id === parseInt(this.groupId),
          )
          const templateResponse = apiCalls[1]
          this.templates = templateResponse
          try {
            const surveyListResponse = await this.httpConnector.makeRequest(
              'get',
              `${this.apiEndpoint}twiliosurveys?code=${this.apiCode}`,
            )
            this.surveys = surveyListResponse.data.map(survey => ({
              label: survey.SurveyName,
              fid: survey.TwilioSurveyFID,
            }))
          } catch (error) {}
          this.close()
          this.isOverlayVisible = false
        } catch (error) {
          // console.log('error :>> ', error)
          this.isOverlayVisible = false
        }
      },
      downloadCSVData () {
        let csv = 'Id, First Name,Last Name, Phone, Email, Status\n'
        this.selected.forEach(row => {
          const line = `${row.id}, ${row.firstName}, ${row.lastName}, ${row.cellNumber}, ${row.email}, ${row.contactStatus}\n`
          csv += line
        })

        const anchor = document.createElement('a')
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        anchor.target = '_blank'
        anchor.download = `${this.currentGroup.name}.csv`
        anchor.click()
      },
      selectAllToggle (props) {
        const nonSelectableContacts = this.contacts.filter(
          contact => contact.contactStatus === 'unsubscribed',
        )
        if (
          this.selected.length <
          this.contacts.length - nonSelectableContacts.length
        ) {
          // Currently selectable rows count is greater than selected rows
          // So select all
          this.selected = []
          const self = this
          this.contacts.forEach(item => {
            if (item.contactStatus !== 'unsubscribed') {
              self.selected.push(item)
            }
          })
        } else {
          // Currently selectable rows count is equal to selected rows
          // So de-select all
          this.selected = []
        }
      },

      messageSendInitiated () {
        this.isOverlayVisible = true
      },

      callRequestInitiated () {
        this.isOverlayVisible = true
      },

      callInitiated (res) {
        this.isOverlayVisible = false
        this.notification = 'Call initiated successfully'
        this.snackbar = true
      },

      callError (error) {
        this.isOverlayVisible = false
        this.notification = 'Unable to initiate the call'
        this.snackbar = true
        throw error
      },

      messageSent (res) {
        this.isOverlayVisible = false
        this.notification = 'Message has been sent'
        this.snackbar = true
      },

      emailSent (res) {
        this.isOverlayVisible = false
        this.notification = 'Email has been sent'
        this.snackbar = true
      },

      messageError (error) {
        let errorMessage = ''
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.length
        ) {
          errorMessage = error.response.data
        } else {
          errorMessage = 'Unable to send the message'
        }
        this.notification = errorMessage
        this.isOverlayVisible = false
        this.snackbar = true
        throw error
      },

      editItem (item) {
        this.editedIndex = this.contacts.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.selectedGroup = this.editedItem.groupId

        this.dialog = true
      },

      deleteItem (item) {
        if (confirm('Are you sure you want to remove this contact?')) {
          this.isOverlayVisible = true
          this.httpConnector
            .makeRequest(
              'post',
              `${this.apiEndpoint}groups/${item.groupId}/remove?contactId=${item.id}&code=${this.apiCode}`,
            )
            .then(async res => {
              this.notification = 'Contact has been removed'
              this.snackbar = true

              await this._loadData()
              this.isOverlayVisible = false
            })
            .catch(error => {
              this.notification = 'Unable to remove the contact'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        }
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        this.editedItem.group = this.groups.find(obj => {
          return obj.id === this.selectedGroup
        }).name

        if (this.editedIndex > -1) {
          const form = {
            FirstName: this.editedItem.firstName,
            LastName: this.editedItem.lastName,
            CellPhone: this.editedItem.cellNumber,
            GroupID: this.selectedGroup,
          }
          this.isOverlayVisible = true
          this.httpConnector
            .makeRequest(
              'put',
              `${this.apiEndpoint}contacts/${this.editedItem.id}?code=${this.apiCode}`,
              form,
            )
            .then(res => {
              this.notification = 'Contact has been updated'
              this.snackbar = true

              Object.assign(this.contacts[this.editedIndex], this.editedItem)

              if (this.selectedGroup !== this.groupId) {
                this.contacts.splice(this.editedIndex, 1)
              }

              this.close()
              this.isOverlayVisible = false
            })
            .catch(error => {
              this.notification = 'Unable to update the contact'
              this.snackbar = true
              this.isOverlayVisible = false
              throw error
            })
        }
      },

      updateStatus (filteredStatus) {
        // On status update refresh the results
        this.contacts = []
        this.isOverlayVisible = true

        let endpoint = `${this.apiEndpoint}group/${this.groupId}/contacts?code=${this.apiCode}`
        if (filteredStatus !== '-1') {
          endpoint += `&Filter=${filteredStatus}`
        }

        this.httpConnector
          .makeRequest('get', endpoint)
          .then(res => {
            res.data.forEach(contact => {
              this.contacts.push({
                id: contact.ContactID,
                firstName: contact.FirstName,
                lastName: contact.LastName,
                cellNumber: contact.CellNumber,
                contactStatus: contact.ContactStatus,
                groupId: contact.GroupID,
              })
            })
            this.isOverlayVisible = false
          })
          .catch(error => {
            this.isOverlayVisible = false
            throw error
          })
      },

      async initiateVideoCall () {
        this.isOverlayVisible = true
        const videoCallRoomId = this.currentGroup.id + +new Date()
        // Fetch video call authen code
        try {
          const authenCodeResponse = await this.httpConnector.makeRequest(
            'get',
            `${this.apiEndpoint}user/videoauthencode?code=${this.apiCode}`,
          )
          if (
            authenCodeResponse &&
            authenCodeResponse.data &&
            authenCodeResponse.data.AuthenCode
          ) {
            const authenTokenForVideoCall = authenCodeResponse.data.AuthenCode
            // Prepare links for each selected contacts
            const userMessages = this.selected.map(user => {
              return {
                ToPhoneNumbers: user.cellNumber,
                Message: `Please join the call here: https://d3luoc1jmeqeuu.cloudfront.net/video-call?room=${videoCallRoomId}&user=${user.cellNumber}&authencode=${authenTokenForVideoCall}`,
                GroupID: this.currentGroup.id,
                CampaignName: `Conference Call on ${new Date()}`,
                Sender: localStorage.getItem('username'),
                MediaURL: '',
              }
            })
            try {
              // Prepare the API calls
              const apiCalls = userMessages.map(userMessage =>
                this.httpConnector.makeRequest(
                  'post',
                  `${this.apiEndpoint}TwilioSendBulkSMS?code=${
                    this.apiCode
                  }&extrenalId=${localStorage.getItem('username')}`,
                  userMessage,
                ),
              )
              await Promise.all(apiCalls)
              // Prepare current user joining link
              const initiatorId = localStorage.getItem('username')
              this.isOverlayVisible = false
              const newWindow = window.open(
                `https://d3luoc1jmeqeuu.cloudfront.net/video-call?room=${videoCallRoomId}&user=${initiatorId}&authencode=${authenTokenForVideoCall}&host=true`,
                '_blank',
              )
              newWindow.focus()
            } catch (error) {
              this.isOverlayVisible = false
              this.notification = 'Unable to initiate conference call'
              this.snackbar = true
            }
          } else {
            throw new Error()
          }
        } catch (error) {
          // Means twilio configs are not set properly
          this.isOverlayVisible = false
          this.notification = 'Please set Video meet credentials first'
          this.snackbar = true
        }
      },

      showSurveyList () {
        this.isSurveyListDialogVisible = true
      },

      hideSurveyDialog () {
        this.isSurveyListDialogVisible = false
        this.selectedSurvey = null
      },

      async sendSurveyLinks () {
        this.isOverlayVisible = true
        // Prepare survey payloads for each users
        const surveyPayloads = this.selected.map(user => {
          return {
            FlowID: this.selectedSurvey, // For survey
            FlowPhoneNumber: '3232741944', // For survey
            ToPhoneNumber: user.cellNumber,
          }
        })
        this.hideSurveyDialog()
        try {
          const apiCalls = surveyPayloads.map(surveyPayload =>
            this.httpConnector.makeRequest(
              'post',
              `${this.apiEndpoint}TwilioExeFlow?code=${this.apiCode}`,
              surveyPayload,
            ),
          )
          await Promise.all(apiCalls)
          this.isOverlayVisible = false
          this.notification = 'Survey links sent!'
          this.snackbar = true
        } catch (error) {
          this.isOverlayVisible = false
          this.notification = 'Unable to send survey links, please try again'
          this.snackbar = true
        }
      },
      composeMassEmail () {
        this.isMassEmailDialogVisible = true
        console.log('this.isMassEmailDialogVisible :>> ', this.isMassEmailDialogVisible)
      },
      dismissMassEmailDialog () {
        this.isMassEmailDialogVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.dropdown {
  /* background: red; */
  /* margin-bottom: -45px; */
  margin-top: 15px !important;
  margin-left: -31px !important;
  padding: 0 !important;
  /* width: 0px; */
}
.dropdown .v-input__slot {
  width: 25vw;
  height: 10px !important;
}
.spinner {
  margin-left: 17rem;
}
.v-overlay.v-overlay--active.theme--dark {
  z-index: 300 !important;
}
@media only screen and (max-width: 968px) {
  .dropdown .v-input__slot {
    width: 45vw;
    height: 10px !important;
  }
  .spinner {
    margin-left: 0;
  }
}
#surveySelector {
  margin: 50px 40px 30px 40px;
}
.searchBox {
  margin-left: 15px;
}
.tableTools {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 0 8px;
  }
}
.cellLink {
  text-decoration: underline;
  color: rgb(0, 65, 161);
  cursor: pointer;
}
</style>
